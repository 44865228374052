:root {
  --orange-color: #f6902c;
  --sub-color: #facb76;
  --black-color: #000;
  --bg-color: #fff;
}

* {
  font-family: 'Open Sans', sans-serif;
}

.cursor-pointer{
  cursor: pointer !important;
}


/*Scrollbar*/
/* Scrollbar for WebKit browsers (Chrome, Safari, Edge) */
::-webkit-scrollbar {
  width: 12px; /* Width of the scrollbar */
  height: 12px; /* Height for horizontal scrollbars */
}

::-webkit-scrollbar-track {
  background: #f0f0f0; /* Light background */
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #888; /* Darker thumb for visibility */
  border-radius: 10px;
  border: 2px solid #f0f0f0; /* Creates a spacing effect */
}

::-webkit-scrollbar-thumb:hover {
  background: #555; /* Darker color on hover */
}

/* Scrollbar for Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #888 #f0f0f0;
}

/* Internet Explorer & Edge (Legacy) */
body {
  -ms-overflow-style: scrollbar;
}
/*Scrollbar*/


/* nav BAr & logo  */
.logo-virtical {
  width: 150px;
}
.logo {
  width: 50px;
}

.ltr {
    direction: ltr;
}
/* Intro-banner */

.hero-section {
  object-fit: cover;
  object-position: center;
  width: 100%;
}

.navbar {
  transition: all 1s;
  -webkit-transition: all 1s;
  -moz-transition: all 1s;
  -ms-transition: all 1s;
  -o-transition: all 1s;
  position: sticky;
  top: 0;
  z-index: 999;
}

.navbar .megamenu {
  padding: 1rem;
}

/* ============ desktop view ============ */
@media all and (min-width: 992px) {

  .navbar .has-megamenu {
    position: static !important;
  }

  .navbar .megamenu {
    left: 0;
    right: 0;
    width: 100%;
    margin-top: 0;
  }

}

.nav-link {
  color: var(--orange-color);
}


.flag {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.user {
  width: 20px;
  height: 20px;
  border: 2px solid #f6902c;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  margin-left: 5px;

}

.user-p span {
  /*font-size: 13px;
  */
  font-weight: bold;
  color: var(--orange-color);
}

.user-p {
  padding: 2px 0 4px 0;
  border: 2px solid var(--orange-color);
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  margin-top: 4px;
}


.header-area {
  background-color: #000;
}

.dropdown-item {
  text-wrap: wrap;
  color: #000;
}

.dropdown-menu {
  border-top: 3px solid #facb76;
  animation: fadeUp 0.8s ease;
  -webkit-animation: fadeUp 0.8s ease;
  background-color: #ffffffab;
  backdrop-filter: blur(7px);
}

.dropdown-item {
  transition: all 0.6s;
  -webkit-transition: all 0.6s;
  -moz-transition: all 0.6s;
  -ms-transition: all 0.6s;
  -o-transition: all 0.6s;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  margin: 10px 0;
}

.dropdown-item.active,
.dropdown-item:hover {
  border-radius: 5px;
  background-color: #f6902c;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  color: var(--bg-color);
}

@keyframes fadeUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
    -webkit-transform: translateY(20px);
    -moz-transform: translateY(20px);
    -ms-transform: translateY(20px);
    -o-transform: translateY(20px);
  }

  100% {
    opacity: 1;
    transform: translateY(-1px);
    -webkit-transform: translateY(-1px);
    -moz-transform: translateY(-1px);
    -ms-transform: translateY(-1px);
    -o-transform: translateY(-1px);
  }
}


.bg-banner {
  background-image: url(./images/bak.jpg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  height: 80vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.bg-banner::after {
  content: '';
  position: absolute;
  left: 100px;
  top: 50px;
  background-image: url(./images/he.png);
  height: 200px;
  width: 200px;
  background-position: cover;
  background-repeat: no-repeat;
  animation: rotate 4s infinite alternate-reverse;
  -webkit-animation: rotate 4s infinite alternate-reverse;
}

.bg-banner::before {
  content: '';
  position: absolute;
  left: 200px;
  top: 100px;
  background-image: url(./images/he1.png);
  height: 200px;
  width: 200px;
  background-position: cover;
  background-repeat: no-repeat;
  animation: translate 4s infinite alternate-reverse;
  -webkit-animation: translate 4s infinite alternate-reverse;
}



@keyframes rotate {
  0% {
    transform: translatey(0px);
    -webkit-transform: translatey(0px);
    -moz-transform: translatey(0px);
    -ms-transform: translatey(0px);
    -o-transform: translatey(0px);
  }

  100% {
    transform: translatey(5px);
    -webkit-transform: translatey(5px);
    -moz-transform: translatey(5px);
    -ms-transform: translatey(5px);
    -o-transform: translatey(5px);
  }
}

@keyframes translate {
  0% {
    transform: translateX(50px);
    -webkit-transform: translateX(50px);
    -moz-transform: translateX(50px);
    -ms-transform: translateX(50px);
    -o-transform: translateX(50px);
  }

  100% {
    transform: translateX(100px);
    -webkit-transform: translateX(100px);
    -moz-transform: translateX(100px);
    -ms-transform: translateX(100px);
    -o-transform: translateX(100px);
  }
}


.content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
}

.content h1 {
  color: var(--black-color);
  font-weight: bold;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
}

.first-section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}


.first-section :first-child {
  color: var(--blue-color);
  font-size: 35px;
}

.first-section :last-child {
  color: #6d6d6d;
  font-size: 30px;
}


.card-box {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0 !important;
  /* box-shadow: 1px 1px 1px 1px #00000069; */
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  position: relative;
  overflow: hidden;
}

.card-boxs {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0 !important;
  /* box-shadow: 1px 1px 1px 1px #00000069; */
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  position: relative;
  overflow: hidden;
  background-color: var(--sub-color);
}

.card-icon {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  background-color: var(--orange-color);
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-boxs .card-icon {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  background-color: var(--bg-color);
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-icon i {
  color: var(--bg-color);
  font-size: 30px;
}

.card-boxs .card-icon i {
  color: var(--orange-color);
  font-size: 30px;
}


.card-text h4 {
  color: var(--blue-color);
  margin-top: 10px;
  font-size: 20px;
}

.card-text p {
  color: #6d6d6d;

}



.card-box:hover {
  background-color: var(--sub-color);
  transform: translatey(-5px);
  -webkit-transform: translatey(-5px);
  -moz-transform: translatey(-5px);
  -ms-transform: translatey(-5px);
  -o-transform: translatey(-5px);
  box-shadow: 0 0 0 0;
}

.card-box:hover .card-icon {
  background-color: var(--bg-color);
}

.card-box:hover .card-icon i {
  color: var(--orange-color);
}

.card-box:hover .card-text p {
  color: var(--white-color);
}

.card-box:hover .card-text h4 {
  color: var(--white-color);
}


.card-box::after {
  content: '';
  position: absolute;
  top: -40px;
  right: -40px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, .1);
  width: 120px;
  height: 120px;
  transition: .3s;
}

.card-boxs::after {
  content: '';
  position: absolute;
  top: -40px;
  right: -40px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, .1);
  width: 160px;
  height: 160px;
  transition: .3s;
}

.card-box:hover::after {
  width: 160px;
  height: 160px;
}


@media (min-width:992px) and (max-width:1199px) {
  .card-box {
    min-height: 230px;
    width: 100%;
  }
}

@media (min-width:768px) and (max-width:991px) {
  .card-box {
    min-height: 250px;
    width: 100%;
  }
}

@media (min-width:576px) and (max-width:767px) {
  .card-box {
    min-height: 255px;
    width: 100%;
    font-size: 12px;
  }
}

@media (max-width:575px) {
  .card-box {
    margin-bottom: 15px;
  }
}

.about-text ul li {
  margin: 10px 0;
}

.about-text i {
  color: var(--orange-color);
}


.about-img {
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.about-sec {
  position: relative;
  z-index: 1;
}

.about-sec::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  background-image: url(./images/banner_bg.png.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  height: 100%;
  width: 100%;
  z-index: -1;
}

.bg-pill {
  background-color: #f6912c96;
}

.about-text h3 {
  font-weight: bold;
  font-size: 30px;
  color: #b4b4b4;
}

.about-text span {
  border-bottom: 3px solid #f6902c;
}

.course-image {
  width: 100%;
  object-fit: cover;
  object-position: center;
  transition: all 0.6s;
  -webkit-transition: all 0.6s;
  -moz-transition: all 0.6s;
  -ms-transition: all 0.6s;
  -o-transition: all 0.6s;
}

.card-cover {
  overflow: hidden;
  padding: 20px;
}

.bg-sec-course {
  position: relative;
  overflow: hidden;
}

.bg-sec-course::after {
  position: absolute;
  top: 300px;
  left: -200px;
  width: 550px;
  height: 550px;
  border-radius: 50%;
  content: '';
  background: #f6902c;
  z-index: -1;
  opacity: 0.7;
}

.bg-sec-course::before {
  position: absolute;
  top: -100px;
  right: -200px;
  width: 550px;
  height: 550px;
  border-radius: 50%;
  content: '';
  background: #facb76;
  z-index: -1;
  opacity: 0.7;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.card-body i,
.card-body p {
  color: var(--orange-color);
}

.card-body span {
  color: #6d6d6d;
}

.course-name h5 {
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
}

.card-course {
  height: 100%;
}

.card-course .card-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}


.card-course:hover .course-image {
  transform: scale(1.05);
  -webkit-transform: scale(1.05);
  -moz-transform: scale(1.05);
  -ms-transform: scale(1.05);
  -o-transform: scale(1.05);
}


.footer-section {
  background-image: url(./images/footer-pattern.png.webp);
  position: relative;
}



.footer-cta {
  border-bottom: 1px solid var(--orange-color);
}

.single-cta {
  display: flex;
}

.single-cta i {
  color: var(--orange-color);
  font-size: 30px;
  margin-top: 8px;
}

.cta-text {
  padding-left: 15px;
  display: inline-block;
}

.cta-text h4 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 2px;
  letter-spacing: 1px;
}

.cta-text span {
  color: #757575;
  font-size: 15px;
  margin: 10px 5px 5px 0;
}

.cta-text span i {
  color: #f6902c;
  font-size: 15px;

}

.footer-content {
  position: relative;
  z-index: 2;
}

.footer-pattern img {
  position: absolute;
  top: 0;
  left: 0;
  height: 330px;
  background-size: cover;
  background-position: 100% 100%;
}

.footer-logo {
  margin-bottom: 30px;
}


.footer-text p {
  margin-bottom: 14px;
  font-size: 14px;
  color: #7e7e7e;
  line-height: 28px;
}

.footer-social-icon span {
  color: var(--orange-color);
  display: block;
  font-size: 20px;
  font-weight: 700;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 20px;
}

.footer-social-icon a {
  color: var(--bg-color);
  font-size: 16px;
  /* margin-right: 15px; */
}

.footer-social-icon i {
  width: 37px;
  text-align: center;
  line-height: 2;
  border-radius: 50%;
  padding-left: 3px;
  transform: translate(0px, 10px);

}



.footer-widget-heading h3 {
  color: var(--main-color);
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 40px;
  position: relative;
  letter-spacing: 3px;
}

.footer-widget-heading h3::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -15px;
  height: 2px;
  width: 50px;
  background: var(--main-color);
}

.footer-widget ul li {
  display: inline-block;
  float: left;
  width: 50%;
  margin-bottom: 12px;
}

.footer-widget ul li a:hover {
  text-decoration: none;
}

.footer-widget ul li a {
  color: var(--orange-color);
  text-transform: capitalize;
  text-decoration: none;
}

.subscribe-form {
  position: relative;
  overflow: hidden;
}

.subscribe-form input {
  width: 100%;
  padding: 14px 28px;
  background: #2E2E2E;
  border: 1px solid #2E2E2E;
  color: #fff;
}



.subscribe-form button i {
  color: #fff;
  font-size: 22px;
  transform: rotate(-6deg);
}

.copyright-area {
  background: #202020;
  padding: 25px 0;
}

.copyright-text p,
.copyright-text span,
.copyright-text a {
  margin: 0;
  font-size: 14px;
  color: #878787;
}



.footer-menu li {
  display: inline-block;
  margin-left: 20px;
}


.footer-menu li a {
  font-size: 14px;
  color: #878787;
}


.wrapper {
  display: flex;
  list-style: none;
  height: 120px;
  width: 100%;
  padding: 40px 0;
  font-family: "Poppins", sans-serif;
  /* justify-content: center; */
}

.wrapper .icon {
  position: relative;
  background: #fff;
  border-radius: 50%;
  margin: 10px;
  width: 50px;
  height: 50px;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrapper .tooltip {
  position: absolute;
  top: 0;
  font-size: 14px;
  background: var(--orange-color);
  /* color: var(--bg-color); */
  padding: 5px 8px;
  border-radius: 5px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  white-space: nowrap;
}

.wrapper .tooltip::before {
  position: absolute;
  content: "";
  height: 8px;
  width: 8px;
  background: #fff;
  bottom: -3px;
  left: 12%;
  transform: translate(-50%) rotate(45deg);
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrapper .icon:hover .tooltip {
  top: -45px;
  /* bottom: 100%; */
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

.wrapper .icon:hover span,
.wrapper .icon:hover .tooltip {
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1);
}


.wrapper .social-icon:hover .tooltip,
.wrapper .social-icon:hover .tooltip::before {
  background: var(--orange-color);
  color: var(--bg-color);
}

.form-control:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: #7e7e7e;
  outline: 0;
  box-shadow: 0 0 0 0 #7e7e7e;
}


.focus {
  position: fixed;
  top: 10%;
  left: 0;
  z-index: 1040;
  height: 100vh;
  width: 100%;
  background-color: #ffffffa6;
  opacity: 0.7;
  display: none;
  backdrop-filter: blur(10px) !important;
}


.form-control {
  background-color: transparent !important;
  backdrop-filter: blur(7px) !important;
}




.form {
  width: 50% !important;
}

@media (max-width:992px) {
  .form {
    width: 100% !important;
  }

  .d-sm {
    display: none;
  }
}


.bg-subj {
  background-image: url(./images/bg-layout.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom;
  height: 500px;
}




html {
  scroll-behavior: smooth;
}

img {
  /* width: 100%; */
  pointer-events: none;
  user-select: none;
}

/* SECTION - HOME */

.home {
  display: grid;
  grid-template-columns: 45% 50%;
  place-items: center;
  gap: 50px;
  /*background: rgb(67, 69, 112);
  */
  /*background: var(--sub-color);
  */
  /*background: radial-gradient(    circle,    rgba(67, 69, 112, 1) 3%,    rgba(35, 36, 57, 1) 60%  );
  */
  overflow: hidden;
  padding: 130px 80px;
  animation: headerAnimation 9s infinite alternate;
  -webkit-animation: headerAnimation 9s infinite alternate;
}

@keyframes headerAnimation {
  0% {
    background: #f6902c;
  }

  50% {
    background: #facb76;
  }

  100% {
    background: #cecece;
  }
}


.description {
  color: #fff;
  padding: 0 50px;
  z-index: 9;
}

.description>h1 {
  font-family: "Tilt Neon", sans-serif;
  font-size: clamp(2.3rem, 5vw, 4rem);
  line-height: 1;
  margin-bottom: 2 logo0px;
}

.gradient-text {
  font-family: "Tilt Neon", sans-serif;
  /* background-image: linear-gradient(90deg,

      rgb(196, 196, 196) 0%,
      rgb(248, 133, 1) 40%,
      rgb(247, 223, 15) 50%,
      rgb(242, 173, 117) 70%,
      rgb(255, 255, 255) 100%); */

  color: #fff;
  background-size: contain;
  background-clip: text;
  -webkit-background-clip: text;
}

.description>p {
  font-family: "Nunito", sans-serif;
  font-size: 1.2rem;
  line-height: 1.5;
  margin-bottom: 30px;
}

#form {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

input:not([type=checkbox]):not([type=radio]) {
  /* padding-inline-end: 10px;
  padding-inline-start: 10px; */
  background-color: transparent;
  outline: transparent;
  /* border: 0;
  border-bottom: 2px solid rgba(255, 255, 255, 0.3); */
  margin-right: 10px;
  transition: all 0.3s ease-in;
  -webkit-transition: all 0.3s ease-in;
}

input[type='checkbox'],
input[type=radio] {
  accent-color: #f6902c;
}

/* input::placeholder {
  color: rgb(117, 152, 242);
  opacity: 0.5;
  font-weight: 500;
}

input[type="email"] {
  color: rgba(255, 255, 255, 0.7);
  font-weight: 500;
  font-size: 1rem;
}

input[type="email"]:focus {
  border: transparent;
  outline: 2px dotted rgb(117, 152, 242);
  outline-offset: -3px;
} */




.users-color-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap: 20px;
}

.item {
  max-width: 200px;
  aspect-ratio: 1/1;
  box-shadow: 0 8px 8px rgba(0, 0, 0, 0.5),
    inset 0px 2px 2px rgba(255, 255, 255, 0.2);
  animation: fadeIn 0.5s linear 1 forwards;
  animation-delay: calc(0.2s * var(--i));
  opacity: 0;
}

.item:nth-child(1) {
  background-color: #67d7e1;
  border-radius: 50% 50% 0 50%;
}

.item:nth-child(2) {
  border-radius: 50% 50% 0 0;
}

.item:nth-child(3) {
  background-color: #6cc164;
  border-radius: 50%;
}

.item:nth-child(4) {
  border-radius: 0 0 0 50%;
}

.item:nth-child(5) {
  border-radius: 0 50% 50% 0;
}

.item:nth-child(6) {
  background-color: #8071a8;
  border-radius: 0 50% 50% 50%;
}

.item:nth-child(7) {
  border-radius: 50% 50% 0 50%;
}

.item:nth-child(8) {
  background-color: #fe7519;
  border-radius: 50% 0 0 50%;
}

.item:nth-child(9) {
  background-color: #f5bec3;
  border-radius: 0 50% 50% 0;
}

.item:nth-child(10) {
  border-radius: 50%;
}

.item:nth-child(11) {
  background-color: #fcd659;
  border-radius: 50% 0 50% 50%;
}

.item:nth-child(12) {
  border-radius: 50% 0 0 0;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: scale(0);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

/* SECTION - CARD CONTAINER */

.card-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  width: 100%;
  min-height: 100vh;
  background: rgb(67, 69, 112);
  background: radial-gradient(circle,
      rgba(67, 69, 112, 1) 3%,
      rgba(35, 36, 57, 1) 60%);
  overflow: hidden;
  padding: 90px 50px;
}



.content {
  position: relative;
  display: grid;
  place-items: center;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(10, 1fr);
  padding: 20px;
  background: rgba(104, 104, 104, 0.5);
  user-select: none;
}

.content::before,
.content::after {
  content: "";
  position: absolute;
  inset: 0;
  z-index: -1;
  border-radius: inherit;
  background: conic-gradient(from var(--gradient-angle),
      rgb(109, 186, 22),
      rgb(30, 119, 95),
      rgb(55, 141, 167),
      rgb(59, 91, 174),
      rgb(144, 118, 236),
      rgb(59, 91, 174),
      rgb(55, 141, 167),
      rgb(30, 119, 95),
      rgb(109, 186, 22));
  animation: rotation 8s linear infinite;
}

.content::after {
  filter: blur(30px);
}

@keyframes rotation {
  0% {
    --gradient-angle: 0deg;
  }

  100% {
    --gradient-angle: 360deg;
  }
}

.content img {
  aspect-ratio: 3/2;
  border-radius: 10px;
  grid-row: 1 / 5;
  margin-bottom: 20px;
  user-select: none;
  object-fit: cover;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.5);
}

.content h1 {
  font-size: 1.2rem;
  color: #fff;
  text-align: center;
  grid-row: 5 / 6;
}

.content p {
  font-size: 1rem;
  font-weight: 400;
  color: #fff;
  grid-row: 6 / 9;
}

.btn-grad {
  background-image: linear-gradient(to right,
      #ece9e6 0%,
      #ffffff 51%,
      #ece9e6 100%);
  padding: 10px 16px;
  color: #000;
  box-shadow: 0 0 5px #eee;
  outline: 0;
  font-size: 1rem;
  font-weight: 400;
  grid-row: 9 / 10;
  cursor: pointer;
  transition: all 0.5s ease-in;
}

.btn-grad:is(:hover, :focus-visible) {
  text-decoration: none;
}

.control {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  list-style: none;
  gap: 14px;
}

.control li {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to bottom, #559b9a, #3b3d8a);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.5),
    inset 0px 4px 4px rgba(255, 255, 255, 0.2);
  padding: 16px;
  border-radius: 50%;
  cursor: pointer;
  transform: scale(1);
}

.control li::before {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(to right, #559b9a, #3b3d8a);
  border-radius: 50%;
  z-index: -1;
  transition: all 0.5s ease-in;
  opacity: 0;
}

.control li:hover::before {
  opacity: 1;
}

.arrow {
  font-size: 1.5rem;
  color: #fff;
}

@media (max-width: 1215px) {
  .description>p {
    font-size: 1rem;
  }

  .btn {
    font-size: 1rem;
  }
}

@media (max-width: 1015px) {
  .home {
    grid-template-columns: 45% 50%;
    gap: 50px;
    padding: 140px 50px;
  }

  .description>p {
    font-size: 1rem;
  }

  .btn {
    padding: 8px 12px;
  }

  .btn-grad {
    padding: 8px 12px;
  }
}

@media (max-width: 865px) {
  .home {
    grid-template-columns: 45% 50%;
    gap: 60px;
    padding: 130px 70px;
  }
}

@media (max-width: 815px) {
  .home {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas:
      "userscolorcontainer"
      "description";
    gap: 30px;
    padding: 90px 80px 70px;
  }

  .users-color-container {
    grid-area: userscolorcontainer;
  }

  .description {
    grid-area: description;
    padding: 0;
    text-align: center;
  }

  .description>p {
    font-size: 1.1rem;
  }

  #form {
    justify-content: center;
  }
}

@media (max-width: 815px) {
  .description>p {
    font-size: 1rem;
  }
}

@media (max-width: 460px) {
  .home {
    gap: 0;
    padding: 30px 40px;
  }

  #form {
    flex-direction: column;
    align-items: center;
    gap: 30px;
    margin-top: 20px;
  }

  input[type="email"]:focus {
    outline-offset: 6px;
  }

}

.nav-bg {
  background-color: #ffffffa9;
  backdrop-filter: blur(7px)
}


.count {
  background-color: #000;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  color: #fff;
  color: var(--bg-color);
  font-size: 13px;
  /* margin-left: 5px; */
  padding: 2px 7px;
  position: absolute;
  top: -6px;
  right: -5px;
}

.nav-link.perant {
  position: relative;
}





.card-image {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 200px;
  width: 200px;
  margin: auto;
}

.card-texts {
  color: var(--orange-color);
  text-align: center;
}

.form-search {
  position: relative;
  top: -50px;
}

.card-texts i,
.card-texts span {
  color: var(--black-color);
  font-weight: 600;
}

@media (max-width:992px) {
  .home {
    display: flex;
    flex-direction: column;
  }
}


.btn-search {
  background-color: transparent !important;
  backdrop-filter: blur(7px) !important;
  width: 50%;
  padding: 0.375rem 0.75rem;
  background-color: var(--bs-body-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  padding: 7px;
  text-align: start;
}

.color-search {
  display: none;
}


@media(max-width:992px) {
  #search-input {
    display: none;
  }

  .color-search {
    display: block;
  }
}



.color-search {
  color: var(--orange-color);
  cursor: pointer;
}

@media (max-width:992px) {
  .user-p {
    display: inline-block;
    padding: 7px;
  }

  .navbar-nav.media {
    align-items: unset !important;
  }
}



.touch-color {
  color: var(--orange-color);
}

.icon-color {
  color: var(--orange-color);
}


.link {
  color: #000;
  font-weight: 600;
}



.icon {
  block-size: 1.50em;
  display: inline-block;
  inline-size: 1.50em;
  line-height: 1.50em;
  margin-right: 0.625em;
  text-align: center;
  vertical-align: middle;
}

.icon--info {
  background-color: #e5e5e5;
  border-radius: 50%;
}


.ic-inf:hover span,
.ic-inf:hover a {
  color: var(--orange-color);
}


fieldset {
  border: none;
  margin: 0;
}



.login__header {
  background-color: var(--orange-color);
  border-top-left-radius: var(--sub-color);
  border-top-right-radius: var(--sub-color);
  color: #fff;
  padding: 1.5em;
  text-align: center;
  text-transform: uppercase;
}

.login__title {
  font-size: 1rem;
  margin: 0;
}

.login__body {
  padding: 1.5em;
  position: relative;
}

.login__body::before {
  background-color: #fff;
  content: '';
  height: 0.5em;
  left: 50%;
  margin-left: -0.25em;
  margin-top: -0.25em;
  position: absolute;
  top: 0;
  transform: rotate(45deg);
  width: 0.5em;
}



.login__footer {
  align-items: center;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  padding-bottom: 1.5em;
  padding-left: 1.5em;
  padding-right: 1.5em;
}

.login__footer p {
  margin: 0;
}


@media (max-width:400px) {
  .login__footer {
    flex-direction: column;
  }

  .login__footer p {
    margin-top: 15px;
  }
}



.Error {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  text-align: center;
}

.Error h1 {
  color: var(--orange-color);
  font-size: 12.5rem;
  letter-spacing: .10em;
  margin: .025em 0;
  text-shadow: .05em .05em 0 rgba(0, 0, 0, .25);
  white-space: nowrap;
}

.Error span {
  display: inline-block;
  animation: spooky 2s alternate infinite linear;
}

.Error h2 {
  margin-bottom: .40em;
}

.Error p {
  color: var(--sub-color);
  margin-top: 0;
}

@keyframes spooky {
  from {
    transform: translatey(.15em) scaley(.95);
  }

  to {
    transform: translatey(-.15em);
  }
}

@media (max-width:992px) {
  .Error h1 {
    color: var(--orange-color);
    font-size: 5.5rem;
    letter-spacing: .10em;
    margin: .025em 0;
    text-shadow: .05em .05em 0 rgba(0, 0, 0, .25);
    white-space: nowrap;
  }
}

.sub-heading {
  text-align: center;
  margin: .5rem 0 1rem;
}

.sub-heading {
  font-weight: 300;
}



.avatar-lg {
  height: 5rem;
  width: 5rem;
}

.font-size-18 {
  font-size: 18px !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

a {
  text-decoration: none !important;
}

.w-xl {
  min-width: 160px;
}

.card {
  margin-bottom: 24px;
  -webkit-box-shadow: 0 2px 3px #e4e8f0;
  box-shadow: 0 2px 3px #e4e8f0;
}

.card {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #eff0f2;
  border-radius: 1rem;
}


.cart-shoping {
  padding: 20px;
  display: flex !important;
  /* justify-content: center; */
  align-items: flex-start;
  flex-direction: row;
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
}

.product-info {
  margin-left: 10px;
}

.product-desc {
  background-color: var(--orange-color);
  color: var(--bg-color);
  padding: 5px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  text-align: center;
}

.price {
  color: #000;
  font-weight: bold;
  font-size: 20px;
}


.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table tbody+tbody {
  border-top: 2px solid #dee2e6;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody+tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary>th,
.table-primary>td {
  background-color: #b8daff;
}

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody+tbody {
  border-color: #7abaff;
}

.table-hover .table-primary:hover {
  background-color: #9fcdff;
}

.table-hover .table-primary:hover>td,
.table-hover .table-primary:hover>th {
  background-color: #9fcdff;
}

.table-secondary,
.table-secondary>th,
.table-secondary>td {
  background-color: #d6d8db;
}

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody+tbody {
  border-color: #b3b7bb;
}

.table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}

.table-hover .table-secondary:hover>td,
.table-hover .table-secondary:hover>th {
  background-color: #c8cbcf;
}

.table-success,
.table-success>th,
.table-success>td {
  background-color: #c3e6cb;
}

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody+tbody {
  border-color: #8fd19e;
}

.table-hover .table-success:hover {
  background-color: #b1dfbb;
}

.table-hover .table-success:hover>td,
.table-hover .table-success:hover>th {
  background-color: #b1dfbb;
}

.table-info,
.table-info>th,
.table-info>td {
  background-color: #bee5eb;
}

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody+tbody {
  border-color: #86cfda;
}

.table-hover .table-info:hover {
  background-color: #abdde5;
}

.table-hover .table-info:hover>td,
.table-hover .table-info:hover>th {
  background-color: #abdde5;
}

.table-warning,
.table-warning>th,
.table-warning>td {
  background-color: #ffeeba;
}

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody+tbody {
  border-color: #ffdf7e;
}

.table-hover .table-warning:hover {
  background-color: #ffe8a1;
}

.table-hover .table-warning:hover>td,
.table-hover .table-warning:hover>th {
  background-color: #ffe8a1;
}

.table-danger,
.table-danger>th,
.table-danger>td {
  background-color: #f5c6cb;
}

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody+tbody {
  border-color: #ed969e;
}

.table-hover .table-danger:hover {
  background-color: #f1b0b7;
}

.table-hover .table-danger:hover>td,
.table-hover .table-danger:hover>th {
  background-color: #f1b0b7;
}

.table-light,
.table-light>th,
.table-light>td {
  background-color: #fdfdfe;
}

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody+tbody {
  border-color: #fbfcfc;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}

.table-hover .table-light:hover>td,
.table-hover .table-light:hover>th {
  background-color: #ececf6;
}

.table-dark,
.table-dark>th,
.table-dark>td {
  background-color: #c6c8ca;
}

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody+tbody {
  border-color: #95999c;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}

.table-hover .table-dark:hover>td,
.table-hover .table-dark:hover>th {
  background-color: #b9bbbe;
}

.table-active,
.table-active>th,
.table-active>td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover>td,
.table-hover .table-active:hover>th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #343a40;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .table-responsive-sm>.table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .table-responsive-md>.table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .table-responsive-lg>.table-bordered {
    border: 0;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .table-responsive-xl>.table-bordered {
    border: 0;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.table-responsive>.table-bordered {
  border: 0;
}


/*  */

.table-wrap {
  overflow-x: auto;
}

.table {
  width: 100%;
  /* background: #fff; */
  -webkit-box-shadow: 0px 5px 12px -12px rgba(0, 0, 0, 0.29);
  -moz-box-shadow: 0px 5px 12px -12px rgba(0, 0, 0, 0.29);
  box-shadow: 0px 5px 12px -12px rgba(0, 0, 0, 0.29);
}


.table thead th {
  border: none;
  padding: 30px;
  font-size: 13px;
  font-size: 18px;
  color: var(--orange-color);
  /* background: #eeeeee !important; */
}

.table tbody tr {
  margin-bottom: 10px;
}

.table tbody th,
.table tbody td {
  /* border: none; */
  /* padding: 30px; */
  font-size: 14px;
  /* background: #fff; */
  /* border-bottom: 4px solid #f8f9fd; */
  vertical-align: middle;
}




.table tbody td .email ul li {
  display: block;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.3);
}

/* .table tbody td .email span:nth-child(2) {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.3);
} */



.chapter span {
  background-color: var(--orange-color) !important;
  padding: 5px;
  color: var(--bg-color);
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.close i {
  color: red;
  cursor: pointer;
  font-size: 20px;
}

.Purchasable span {
  color: red;
  font-weight: bold;
}

tfoot tr td {
  background-color: #eee !important;
  font-weight: bold;
  font-size: 20px;
  color: var(--orange-color) !important;
}

@media (max-width:300px) {
  .bg-sec-course {
    padding: 0 !important;
  }
}


@media (max-width:992px) {
  .course-name h5 {
    font-size: 13px;
  }
}


.single-chapter {
  background-color: var(--black-color);
  margin-top: 10px;
  padding: 10px;
  color: var(--bg-color);
}

.Full-chapter {
  background-color: var(--black-color);
  margin-bottom: 10px;
  padding: 10px;
  color: var(--bg-color);
}

.card-header a,
.card-header p {
  color: var(--orange-color);
  font-weight: 600;
  margin-left: 10px;
}

.samll-img {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.fas.fa-lock,
.fas.fa-lock-open {
  font-size: 14px;
  color: #767676d4 !important
}

.accordion-button .fas.fa-chevron-down {
  transition: 0.3s linear;
}

.accordion-button.collapsed .fas.fa-chevron-down {

  transform: rotate(-90deg)
}

.accordion-button h2 {

  margin-top: 15px;
}

/* .form-check {
  font-size: 13px;
}

.form-check-input:checked {
  background-color: #f6902c !important;
  border-color: #cdcdcd !important;
}

.form-control:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: #f6902c;
  outline: 0;
  box-shadow: 0 0 0 #f6902c;
}

.form-check-input:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: #f6902c;
  outline: 0;
  box-shadow: 0 0 0 #f6902c;
} */



.Full-Course ul li {
  float: left;
  width: 70%;
  margin: 10px 0;
}


.anchor-link {
  color: var(--orange-color);
  text-decoration: none;
  font-size: 12px;
  cursor: pointer;
}

.anchor-link.active {
  color: #fff;
}

.anchor-link.active h6 {
  color: #fff;
  background-color: #000 !important;
}

.anchor-link h6 {
  padding: 10px;
  background-color: #6668733d;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}


.accordion-button::after {
  flex-shrink: 0;
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-image: none !important;
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
}

.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: #eeeeee !important;
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}


.accordion-button:focus {
  z-index: 3;
  border-color: var(--bs-accordion-btn-focus-border-color);
  outline: 0;
  box-shadow: 0 0 0 0 !important;
}


.card-body {
  border-bottom: 1px solid #eeeeee;
}



.card-info .card-body:last-child {
  border: none !important;
}

.head-section {
  color: var(--orange-color);
  position: relative;
}

.head-section::after {
  content: "";
  position: absolute;
  top: 17px;
  left: 140px;
  background-color: var(--orange-color);
  height: 1px;
  width: 80%;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

@media (min-width:768px) and (max-width:791px) {
  .head-section::after {
    content: "";
    position: absolute;
    top: 17px;
    left: 120px;
    background-color: var(--orange-color);
    height: 1px;
    width: 80%;
  }
}

@media (max-width:767px) {
  .head-section::after {
    content: "";
    position: absolute;
    top: 17px;
    left: 120px;
    background-color: var(--orange-color);
    height: 1px;
    width: 60%;
  }
}

.layer-video {
  padding: 20px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  position: relative;
}

.layer-video img {
  max-width: 100%;
  width: 50%;
  height: auto;
  object-fit: cover;
  object-position: center;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;

}

.video-text {
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.video-text h6 {
  font-weight: 700;
}

.vid-lay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #0000005d;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.vid-lay i {
  color: var(--orange-color);
  font-size: 50px;
  cursor: pointer;
}


.card-video {
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  -ms-border-radius: 10px !important;
  -o-border-radius: 10px !important;
}


.modal-contents {
  background-color: #000 !important;
}


.clse {
  color: var(--bg-color);
  font-size: 30px;
  cursor: pointer;
}

.modal-titles {
  color: var(--bg-color);
}

.layer-Bdf {
  padding: 20px;
  background-color: #eeeeee;
  margin: 20px auto;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.layer-Bdf i {
  color: var(--orange-color);
  font-size: 40px;
}


.inst-box {
  max-width: 100%;
}

.inst-box img {
  max-width: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.layer {
  position: relative;
}

.layer-card {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #00000069;
  display: flex;
  justify-content: center;
  align-items: center;
}

.layer-card h5 {
  color: var(--bg-color);
  font-weight: 700;
}

.color {
  color: var(--black-color);
  font-weight: 600;
}

.colors {
  color: var(--orange-color);
}


.card-h100 {
  min-height: 50vh;
}

.prof-card ul li {
  margin: 10px;
}

.card-Bdf {
  cursor: pointer;
}



.order h6 {
  padding: 10px;
  background-color: #eeeeee;
  color: #000;
  font-weight: 600;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}




#invoice {
  position: relative;
  top: 0;
  margin: 0 auto;
  width: 100%;
  background: #FFF;
}

[id*='invoice-'] {
  /* Targets all id with 'col-' */
  border-bottom: 1px solid #EEE;
  padding: 30px;
}

#invoice-top {
  min-height: 120px;
}

#invoice-mid {
  min-height: 120px;
}

#invoice-bot {
  min-height: 250px;
}


.clientlogo img {
  float: left;
  height: 60px;
  width: 60px;
  border: 2px solid #000;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.info {
  display: block;
  float: left;
  margin-left: 20px;
  color: var(--orange-color);
}

.title {
  float: right;
}

.title p {
  text-align: right;
}

#project {
  margin-left: 52%;
}

table {
  width: 100%;
  border-collapse: collapse;
}

.tableitem {
  padding: 5px 0 5px 15px;
  border: 1px solid #EEE
}

.tabletitle {
  padding: 5px;
  background: #EEE;
}

#legalcopy {
  margin-top: 30px;
}

.effect2 {
  position: relative;
}

.effect2:before,
.effect2:after {
  z-index: -1;
  position: absolute;
  content: "";
  bottom: 15px;
  left: 10px;
  width: 50%;
  top: 80%;
  max-width: 300px;
  background: #777;
  -webkit-box-shadow: 0 15px 10px #777;
  -moz-box-shadow: 0 15px 10px #777;
  box-shadow: 0 15px 10px #777;
  -webkit-transform: rotate(-3deg);
  -moz-transform: rotate(-3deg);
  -o-transform: rotate(-3deg);
  -ms-transform: rotate(-3deg);
  transform: rotate(-3deg);
}

.effect2:after {
  -webkit-transform: rotate(3deg);
  -moz-transform: rotate(3deg);
  -o-transform: rotate(3deg);
  -ms-transform: rotate(3deg);
  transform: rotate(3deg);
  right: 10px;
  left: auto;
}

.legal {
  width: 70%;
}

.card-link .card-title {
  color: var(--orange-color) !important;
  text-decoration: none;
}


.input-group-prepend,
.input-group-text {
  border-right: 0 !important;
  border-radius: 0 !important;
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  -ms-border-radius: 0 !important;
  -o-border-radius: 0 !important;
}

.card-Recent {
  margin-top: 15px;
}

.recent-header {
  background-color: transparent;
  color: var(--orange-color);
  padding: 20px;
  border-radius: 0 !important;
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  -ms-border-radius: 0 !important;
  -o-border-radius: 0 !important;
}

.recent-img {
  display: flex;
  align-items: center;
}

.date p a {
  margin-bottom: 0 !important;
  color: #000;
  font-weight: bold;
}

.recent-img img {
  width: 50px;
  height: 50px;
  margin-bottom: 15px;
  border-radius: 50%;
  margin-right: 10px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}


.date span {
  color: #919191;
}


.info-course p a {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  color: var(--orange-color);
  font-weight: bold;
}

.info-course {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 768px) {
  div.total-card {
      right: 0;
      left: 0;
      position: fixed;
      bottom: 5px;
      background: #FFF;
      z-index: 99;
  }
}